/* Countdown.css */

.countdown-container {
    background-image: url('./images/NewGradient1.png'); /* Remove this line */ /* Add this solid color instead */
    padding: 3rem 2rem;
    border-radius: 20px;
    box-shadow: 0 10px 30px rgba(76, 175, 80, 0.1);
    text-align: center;
    max-width: 900px;
    height: 500px;
    margin: 2rem auto;

  }
  
  .countdown-title {
    padding-top: 60px;
    color: black;
    font-size: 3.5rem;
    font-weight: 650;
    letter-spacing: 2px;
  }
  .countdown-tag{
    margin-bottom: 2.4rem;
    font-size: 1.2rem;
    font-weight: 200;
  }
  
  .countdown-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;
  }
  
  .countdown-item {
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(6px);
    -webkit-backdrop-filter: blur(6px);
    padding: 1.5rem;
    border-radius: 15px;
    min-width: 120px;
    position: relative;
    perspective: 400px;
    box-shadow: 0 4px 15px rgba(76, 175, 80, 0.1);
  }
  
  .countdown-value {
    font-size: 3.5rem;
    font-weight: 700;
    color: #1b5e20;
    font-family: 'Arial', sans-serif;
    position: relative;
    transform-origin: bottom;
    animation: flipNumber 0.6s ease-in-out;
    transform-style: preserve-3d;
  }
  
  @keyframes flipNumber {
    0% {
      transform: rotateX(90deg);
      opacity: 0;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      transform: rotateX(0deg);
      opacity: 1;
    }
  }
  
  .countdown-value.animate {
    animation: flipNumber 0.6s ease-in-out;
  }
  
  .countdown-label {
    color: #66bb6a;
    font-size: 0.9rem;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-top: 0.5rem;
  }
  
  .countdown-separator {
    color: #43a047;
    font-size: 2.5rem;
    font-weight: 700;
    margin-top: -1rem;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .countdown-container {
      padding: 2rem 1.2rem;
    }
  
    .countdown-item {
    margin: 10px;
      min-width: 90px;
      padding: 1rem;
    }
  
    .countdown-value {
      font-size: 2.5rem;
    }
  
    .countdown-label {
      font-size: 0.8rem;
    }
  
    .countdown-separator {
      font-size: 2rem;
    }
  }
  
  @media (max-width: 480px) {
    .countdown-wrapper {
      gap: 0.5rem;
    }
  
    .countdown-item {
      min-width: 70px;
      padding: 0.8rem;
    }
  
    .countdown-value {
      font-size: 2rem;
    }
  
    .countdown-label {
      font-size: 0.7rem;
    }
  
    .countdown-separator {
      font-size: 1.5rem;
    }
  }
  
  .countdown-tagline {
    color: #2e7d32;
    font-size: 2.5rem;
    margin-top: 2rem;
    font-weight: 700;
    line-height: 1.2;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    animation: fadeInUp 0.8s ease-out;
  }
  
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* For mobile responsiveness */
  @media (max-width: 768px) {
    .countdown-tagline {
      font-size: 1.8rem;
      margin-top: 1.5rem;
    }
  }
  
  .gradient-text {
    background: linear-gradient(
      300deg,
      #4caf50,    /* Green */
      #00bcd4    /* Blue */    /* Purple */
    );
    background-size: 300% 300%;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: gradientFlow 8s ease infinite;
    font-size: 3.5rem;
    font-weight: 800;
    letter-spacing: 2px;
  }
  
  @keyframes gradientFlow {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .gradient-text {
      font-size: 2.5rem;
    }
  }
  
  @media (max-width: 480px) {
    .gradient-text {
      font-size: 2rem;
    }
  }
  
  .close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.9);
    border: none;
    font-size: 24px;
    color: #2e7d32;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 10;
  }
  
  .close-button:hover {
    background: #2e7d32;
    color: white;
    transform: rotate(90deg);
  }
  
  .close-button:focus {
    outline: none;
  }
  