.testimonial-card {
  margin-bottom: 2rem;
  transition: all 0.3s ease;
  border: 2px solid white;
  border-radius: 15px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.15);
  padding: 2rem;
  min-height: 300px;
  width: 100%;
}

.testimonial-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 40px 0 rgba(31, 38, 135, 0.2);
}

.testimonial-card:focus {
  outline: none;
}

/* Style for company name */
.testimonial-card h5 {
  color: #333;
  font-weight: 600;
  margin-bottom: 0.5rem;
  font-size: 1.3rem !important;
}

/* Style for feedback text */
.testimonial-card p {
  color: #666;
  line-height: 1.8;
  margin-bottom: 0;
  font-size: 1.1rem !important;
  margin-top: 1.5rem;
}

/* Style for rating stars */
.testimonial-card .text-warning {
  color: #ffc107 !important;
  font-size: 1.1rem !important;
}

/* Adjust company logo */
.testimonial-card img {
  border: 2px solid white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  width: 100px !important;
  height: 100px !important;
}

/* Position the indicators below the cards */
.carousel-indicators {
  bottom: -50px;
  z-index: 2;
}

/* Style the indicator buttons */
.carousel-indicators button {
  width: 12px !important;
  height: 12px !important;
  border-radius: 50% !important;
  background-color: black !important;
  border: none !important;
  margin: 0 6px !important;
  opacity: 0.3;
  transition: all 0.4s ease;
  position: relative;
  overflow: hidden;
}

/* Active dot style */
.carousel-indicators button.active {
  background-color: black !important;
  opacity: 1;
  transform: scale(1.2);
}

/* Hover effect for dots */
.carousel-indicators button:hover {
  opacity: 0.7;
}

/* Animation for transitioning dots */
.carousel-indicators button::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  transform: translateX(-100%);
  transition: transform 0.4s ease;
}

.carousel-indicators button.active::after {
  transform: translateX(0);
}

/* Adjust carousel container to account for dots */
#testimonialCarousel {
  padding-bottom: 60px;
}
