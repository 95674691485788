.navbar {
  background: white;
    padding: 0.7rem;
    padding-left: 80px;
    padding-right: 80px;
    background-image: url('./images/NewGradient1.png'); /* Semi-transparent background */
    backdrop-filter: blur(10px); /* Blur effect for glassmorphism */
    -webkit-backdrop-filter: blur(20px); /* For Safari support */
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  }
  
  .navbar-logo {
    height: 75px;
    width: 220px;
  }
  
  .navbar-brand {
    font-size: 1.5rem;
  }
  
  .nav-link {
    color: black !important;
    padding: 0.5rem 1rem;
    font-size: 1.2rem;
    font-weight: 350;
    transition: color 0.3s ease;
  }
  
  .nav-link:hover {
    color: #41d79e !important;
  }
  
  .btn-jatayu {
    padding: 12px 30px;
    font-size: 1.2rem;
    font-weight: 600;
    border-radius: 15px;
    border: none;
    background-color: white;
    color: #41d79e;
    letter-spacing: 1px;
    transition: all 0.3s ease;
    box-shadow: 0 4px 15px rgba(76, 175, 80, 0.3);
  }
  
  .btn-jatayu:hover {
    background-color: white;
    color: #41d79e;
    transform: scale(1.02);
  }
  
  @media (max-width: 768px) {
    .navbar {
      padding: 1rem;
      border-radius: 0;
      min-height: 80px;
    }
  
    .navbar-logo {
      height: 60px;
    }
  
    .navbar-brand {
      font-size: 1.2rem;
    }
  
    .nav-link {
      padding: 0.25rem 0.5rem;
      font-size: 0.9rem;
    }
  
    .btn-danger {
      padding: 0.2rem 0.5rem;
      display: none;
    }
  
    .navbar-nav {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
  
    .nav-item {
      width: 100%;
    }
  
    .nav-link {
      display: block;
      text-align: center;
      width: 100%;
      padding: 0.5rem 0;
    }
  
    .btn-jatayu {
      display: block;
      margin: 1rem auto;
      width: 180px;
    }
  }
  