.meeting-form-container {
    max-width: 600px;
    margin-top: 100px;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  .form-title {
    font-family: "Poppins", sans-serif;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
  }
  
  .meeting-form .form-group {
    margin-bottom: 20px;
  }
  
  .meeting-form .form-group label {
    font-family: "Poppins", sans-serif;
    font-weight: bold;
    color: #333;
  }
  
  .meeting-form .form-group input,
  .meeting-form .form-group textarea {
    font-family: "Poppins", sans-serif;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #ccc;
  }
  
  .meeting-form .btn {
    background-color: #09b875;
    color: white;
    font-family: "Poppins", sans-serif;
    border-radius: 8px;
    padding: 10px 20px;
  }
  .form-control{
    max-width: 80%;
  }
  
  .meeting-form .btn:hover {
    background-color: #067d5b;
  }
/* Padding for MeetingForm to prevent merging with navbar in desktop view */
    