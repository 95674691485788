.finance-info-section {
  padding: 100px 5%;
  background: white;
  position: relative;
  overflow: hidden;
}

.finance-header {
  text-align: center;
  margin-bottom: 80px;
  position: relative;
}

.finance-header h2 {
  font-size: 3rem;
  color: #1a2a6c;
  margin-bottom: 1.5rem;
  font-weight: 700;
}

.finance-header .highlight {
  background: linear-gradient(120deg, #64ffda, #1a2a6c);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.finance-subtitle {
  font-size: 1.4rem;
  color: #666;
  max-width: 700px;
  margin: 0 auto;
  line-height: 1.6;
}

.finance-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  max-width: 1400px;
  margin: 0 auto;
  padding: 20px;
}

.finance-card {
  position: relative;
  border-radius: 20px;
  overflow: hidden;
  background: #fff;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  height: 380px;
  width: auto;
}

.finance-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
}

.finance-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.finance-card:hover img {
  transform: scale(1.1);
}

.card-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    to bottom,
    rgba(26, 42, 108, 0.2),
    rgba(26, 42, 108, 0.9)
  );
  z-index: 1;
}

.card-content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 2rem;
  z-index: 2;
  color: white;
  transform: translateY(60px);
  transition: transform 0.3s ease;
}

.finance-card:hover .card-content {
  transform: translateY(0);
}

.card-content h3 {
  font-size: 1.8rem;
  margin-bottom: 1rem;
  font-weight: 600;
}

.card-content p {
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 1.5rem;
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.3s ease 0.1s;
}

.finance-card:hover .card-content p {
  opacity: 1;
  transform: translateY(0);
}

.learn-more {
  background: transparent;
  border: 2px solid #64ffda;
  color: #64ffda;
  padding: 0.8rem 1.5rem;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  opacity: 0;
  transform: translateY(20px);
}

.finance-card:hover .learn-more {
  opacity: 1;
  transform: translateY(0);
}

/* Responsive Design */
@media (max-width: 968px) {
  .finance-container {
    grid-template-columns: 1fr; /* Display 1 card per row */
    gap: 1.5rem; /* Adjust the gap for better spacing */
    padding: 10px; /* Add padding for better alignment */
  }

  .finance-card {
    height: 300px; /* Set a fixed height for cards */
    width: 100%; /* Ensure cards take full width */
  }

  .card-content {
    padding: 1.5rem; /* Adjust padding for smaller cards */
  }
  .learn-more {
    padding: 0.3rem 1rem; /* Reduce padding */
    font-size: 0.9rem; /* Reduce font size */
    border-radius: 4px; /* Slightly smaller border radius */
  }
}

@media (max-width: 768px) {
  .card-content p {
    margin: 0.5rem 0; /* Small margin for spacing */
    padding: 0; /* Remove padding */
    line-height: 1.4; /* Adjust line height for better readability */
  }
}
