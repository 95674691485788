body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: transparent;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* src/App.css */
.content {
  padding-top: 50px; /* Adjust based on your navbar height */
}

/* Media Queries for Mobile View */
@media (max-width: 768px) {
  .content {
    padding-top: 80px; /* Reduced padding for mobile */
  }

  /* Additional styles for mobile view can be added here */
  body {
    font-size: 14px; /* Smaller font size for mobile */
  }

  /* Example: Adjusting margins or other elements */
  h1 {
    font-size: 24px; /* Smaller heading size */
    text-align: center; /* Center align headings on mobile */
  }

  p {
    margin: 0 10px; /* Reduce side margins for paragraphs */
  }

  /* You can add more styles to adjust layouts, buttons, etc. */
}
