.about-ceo-container {
  padding: 60px 20px;
  max-width: 1200px;
  margin: 0 auto;
}
.main-title {
  text-align: center;
  font-size: 2.8rem;
  color: #333;
  margin-bottom: 50px;
  font-weight: 600;
}

.profile-card {
  display: flex;
  align-items: center;
  gap: 40px;
  margin-bottom: 60px;
  padding: 40px;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.05));
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.1);
}

.profile-image {
  flex: 0 0 300px;
}

.profile-image img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 15px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.profile-content {
  flex: 1;
  padding: 0 40px;
}

.profile-title {
  font-size: 2rem;
  background: linear-gradient(135deg, #31a76f, #218838);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 15px;
  font-weight: 600;
}

@supports not (background-clip: text) {
  .profile-title {
    color: #31a76f;
  }
}

.profile-name {
  font-size: 1.5rem;
  color: #666;
  margin-bottom: 25px;
}

.profile-description {
  color: #555;
  line-height: 1.8;
  margin-bottom: 30px;
  text-align: justify;
}

.contact-btn {
  padding: 12px 30px;
  background: linear-gradient(135deg, #31a76f, #218838);
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.contact-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(49, 167, 111, 0.4);
}

@media (max-width: 768px) {
  .profile-card {
    flex-direction: column;
    text-align: center;
    padding: 20px;
  }
  .profile-description {
    margin-bottom: 15px;
    text-align: left;
  }

  .profile-card {
    display: flex;
    flex-direction: column;
  }

  .profile-image {
    flex: 0 0 auto;
    width: 200px;
    margin: 0 auto;
    order: -1;
  }

  .profile-content {
    order: 2;
  }

  .profile-image img {
    height: 200px;
  }

  .main-title {
    font-size: 2.2rem;
  }

  .profile-title {
    font-size: 1.8rem;
  }

  .profile-name {
    font-size: 1.3rem;
  }

  .profile-content {
    padding: 15px;
  }
}
