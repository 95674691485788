/* General Section Styles */

.contact-section {
    padding: 5rem 0;
    background: linear-gradient(to bottom, white, #f9fafb);
  }
  
  .container {
    max-width: 1200px;
    margin: auto;
    padding: 0 1rem;
    .follow-us h4 {
        font-size: 1.25rem;
        font-weight: bold;
        margin-bottom: 1rem;
        color: white;
      }
      
      .follow-us .flex {
        display: flex;
        gap: 1rem;
      }
      
      .follow-us .flex a {
        background-color: rgba(59, 130, 246, 0.1); /* Adjusted alpha for more transparency */        padding: 0.5rem;
        border-radius: 0.5rem;
        transition: background-color 0.3s;
      }
      
      .follow-us .flex a:hover {
        background-color: rgba(59, 130, 246, 0.1);
        
      }
      
      .follow-us img {
        width: 1.25rem;
        height: 1.25rem;
      }
      
  }
  
  .max-width {
    margin: auto;
  }
  
  .header {
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .sec-head {
    font-size: 2.7rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
    color:#41d79e;
  }
  
  .sub-heading {
    font-size: 1rem;
    color: #6b7280;
    max-width: 600px;
    margin: 0 auto;
  }
  
  /* Grid Styles */
  .grid-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
  }
  
  @media (min-width: 1024px) {
    .grid-container {
      grid-template-columns: 2fr 3fr;
    }
  }
  
  /* Contact Information */
  .contact-info {
    background: #41d79e;
    color: white;
    border-radius: 1rem;
    padding: 2rem;
    position: relative;
  }
  
  .info-title {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
  
  .info-item {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
  }
  
  .icon-wrapper {
    background-color: rgba(59, 130, 246, 0.1); 
    padding: 1rem;
    border-radius: 0.5rem;
  }
  
  .info-label {
    font-size: 0.875rem;
    color: rgba(255, 255, 255, 0.8);
    padding-top: 18px;
  }
  
  .info-detail {
    font-size: 1rem;
    font-weight: bold;
  }
  
  /* Contact Form */
  .contact-form {
    background: white;
    border-radius: 1rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 2rem;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.form-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
}

@media (min-width: 600px) {
  .form-grid {
    grid-template-columns: 1fr 1fr;
  }
}

.form-group {
  display: flex;
  flex-direction: column;
}

label {
  font-size: 0.875rem;
  color: #4b5563;
  margin-bottom: 0.5rem;
}

input, textarea {
  padding: 0.75rem;
  border: 1px solid #d1d5db;
  border-radius: 0.5rem;
  font-size: 1rem;
}

input:focus, textarea:focus {
  outline: none;
  border-color: #2563eb;
  box-shadow: 0 0 0 3px rgba(37, 99, 235, 0.2);
}

textarea {
  resize: none;
}

.submit-button {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  background: #41d79e;
  color: white;
  font-size: 0.875rem;
  font-weight: bold;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: background 0.3s;
  width: 170px;
}

.submit-button:hover {
  background: green;
}

.submit-button svg {
  width: 1.25rem;
  height: 1.25rem;
}

/* Animation */
.section-visible {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.5s, transform 0.5s;
}

.section-heading {
  opacity: 0;
  transform: translateY(20px);
}

@media (max-width: 600px) {
    .contact-form {
        padding-right: 70px; /* Adjust padding for mobile view */
    }
    .grid-container{
      padding-right: 70px;
    }
}

  