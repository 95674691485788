/* Timeline.css */
@media (max-width: 768px) {
    .text-center img {
        max-width: 100%;
        height: auto;
    }
    .timeline {
        text-align: left; /* Align text to the left */
    }
    .event p {
        text-align: left; /* Align paragraphs to the left */
        margin: 0; /* Remove default margin to reduce spacing */
        line-height: 1.5; /* Adjust line height for better readability */
    }
}

body {
    font-family: 'Poppins', sans-serif;
}

.timeline {
    position: relative;
    padding-left: 40px;
    margin-left: 20px;
    border-left: 3px solid #bef5e0;
}

.timeline .event {
    position: relative;
    margin-bottom: 40px;
}

.text-center img {
    max-width: 100%;
    height: auto;
}

.timeline .event .circle {
    position: absolute;
    left: -51px;
    top: 0;
    background-color: #09b875;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    z-index: 1;
}

.timeline .event .title {
    font-weight: bold;
    color: #09b875;
    font-size: 1.3rem;
}

.timeline .event .icon {
    color: green;
    margin-right: 5px;
}

.timeline .event p {
    color: black;
    font-size: 1.1rem;
    margin: 1;
    opacity: 1; /* Ensure visibility */
}