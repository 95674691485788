.applications-card {
  background: #f9f9f9;
  border-radius: 12px;
  width: 250px; /* Increased width */
  height: 250px; /* Keep height consistent */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  transition: transform 0.3s ease, opacity 0.3s ease; /* Add transition for smooth zoom */
}

.applications-card:hover {
  transform: scale(1.05); /* Zoom in effect */
  opacity: 0.9; /* Slight fade effect on hover */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); /* Add shadow on hover */
}

.applications-icon-container {
  background: #f3f5f9;
  padding: 10px;
  width: 100px; /* Icon container width */
  height: 100px; /* Icon container height */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.applications-card-title {
  font-size: 1rem;
  font-weight: bold;
  margin-top: 10px;
}
.separator-image {
  width: 100%; /* Ensure images take the full width of their column */
  height: auto;
  margin-top: 70px;
}

/* Hide the vertical separator image by default */
.vertical {
  display: none; /* Hidden on larger screens */
}
@media (max-width: 767px) { /* Mobile breakpoint */
  .horizontal {
    display: none; /* Hide horizontal image on mobile */
  }

  .vertical {
    display: block; /* Show vertical image on mobile */
    height: 100px; /* Adjust height as needed */
  }
}