/* styles.css */
body {
    font-family: 'Poppins', sans-serif;
    background-color: #f5f5f5;
}


.card {
    transition: transform 0.3s, opacity 0.3s;
}

.card:hover {
    transform: translateY(-10px);
    opacity: 0.9;
}

.card h3 {
    font-size: 24px;
    margin: 0;
    color: #333;
}

.card p {
    font-size: 16px;
    margin: 5px 0 0;
    color: black;
}
