.animation-background {
    position: absolute; /* Position it absolutely */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1; /* Send it behind other content */
  }
  
  .solutions-container {
    position: relative; /* Ensure the container is relative for absolute positioning */
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .section-title {
    text-align: center;
    margin-bottom: 2rem;
    font-size: 2rem;
    font-weight: bold;
  }
  
  .row {
    margin-bottom: 1.5rem;
  }
  
  .challenge-box,
  .solution-box {
    padding: 1.5rem;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .challenge-box {
    background-color: #fff; /* Background color for challenges */
  }
  
  .solution-box {
    background-color: #f8f9fa; /* Background color for solutions */
  }
  
  .challenge-title {
    color: darkorange; /* Updated color for problems */
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }
  
  .solution-title {
    color: #31a76f; /* Updated color for solutions */
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }
  
  .challenge-text,
  .solution-text {
    color: #333;
    line-height: 1.6;
  }
  
  .solution-image,
  .solution-image-mobile {
    max-width: 100%;
    height: auto;
  }
  
  .solution-image-mobile {
    display: none;
  }
  
  @media (max-width: 767px) {
    .solution-image {
      display: none;
    }
    .solution-image-mobile {
      display: block;
      margin: 0 auto;
      max-width: 100%;
      height: 50%; /* Set height to 50% */
      object-fit: contain; /* Maintain aspect ratio */
    }
  
    /* Centering h4 elements in mobile view */
    .challenge-title,
    .solution-title {
      text-align: center; /* Center text for h4 elements */
    }
  }
  
  .timeline-animation-container {
    margin-top: 3rem;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  
  .mobile-aligned {
    margin: 0; /* Adjust margin as needed */
    padding: 0; /* Adjust padding as needed */
  }
  
  